<template>
  <b-card-actions title="Students" action-collapse :collapsed="false" no-body class="m-0">
    <div class="m-2 mb-0">
      <b-row>
        <!-- Per Page -->
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
            <b-button variant="primary" @click="isAddNewStudentActive = true">
              <span class="text-nowrap">Add Student</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refStudentsListTable"
      class="position-relative"
      :items.sync="fetchProfiles"
      :fields="tableColumns"
      responsive
      primary-key="id"
      :sort-by.sync="sortBy"
      :sort-desc.sync="isSortDirDesc"
      :sort-by-formatted="true"
      show-empty
      empty-text="No matching records found"
    >
      <!-- Column: Session -->
      <template #cell(studentId)="data">
        {{ data.item.student.studentId }}
      </template>

      <!-- Column: Session -->
      <template #cell(name)="data">
        <b-link
          :to="{ name: 'apps-students-view', params: { id: data.item.student.id } }"
          class="font-weight-bold d-block text-nowrap"
        >
          {{ data.item.student.fullName }}
        </b-link>
        <small class="text-muted">#{{ data.item.student.studentId }}</small>
      </template>

      <!-- Column: Session -->
      <template #cell(email)="data">
        {{ data.item.student.email }}
      </template>

      <!-- Column: Attributes - Program -->
      <template #cell(fundingSource)="data">
        <b-badge
          pill
          :variant="`light-${resolveProfileProgramVariant(data.item.attributes.fundingSource)}`"
          class="text-capitalize"
        >
          {{ title(data.item.attributes.fundingSource) }}
        </b-badge>
      </template>

      <!-- Column: Attributes - Payment Status -->
      <template #cell(paymentStatus)="data">
        <b-badge
          pill
          :variant="`light-${resolveProfilePaymentStatusVariant(data.item.attributes.paymentStatus)}`"
          class="text-capitalize"
        >
          {{ title(data.item.attributes.paymentStatus) }}
        </b-badge>
      </template>

      <!-- Column: Attributes - Uploaded Resume -->
      <template #cell(uploadedResume)="data">
        <feather-icon
          :id="`profile-row-${data.item.id}-upload-resume-icon`"
          icon="UploadIcon"
          class="cursor-pointer"
          size="16"
          @click="startUpload(data.item, 'enrollment', 'resume')"
        />

        <b-tooltip title="Upload Resume" class="cursor-pointer" :target="`profile-row-${data.item.id}-upload-resume-icon`" />

        <b-badge
          pill
          :variant="`light-${resolveProfileYNVariant(data.item.attributes.uploadedResume)}`"
          class="text-capitalize"
        >
          {{ title(data.item.attributes.uploadedResume) }}
        </b-badge>
      </template>

      <!-- Column: Attributes - Uploaded COE -->
      <template #cell(uploadedCoe)="data">
        <feather-icon
          :id="`profile-row-${data.item.id}-upload-coe-icon`"
          icon="UploadIcon"
          class="cursor-pointer"
          size="16"
          @click="startUpload(data.item, 'enrollment', 'coe')"
        />

        <b-tooltip title="Upload COE" class="cursor-pointer" :target="`profile-row-${data.item.id}-upload-coe-icon`" />

        <b-badge
          pill
          :variant="`light-${resolveProfileYNVariant(data.item.attributes.uploadedCoe)}`"
          class="text-capitalize"
        >
          {{ title(data.item.attributes.uploadedCoe) }}
        </b-badge>
      </template>

      <!-- Column: Attributes - VA Submission -->
      <template #cell(submittedToVa)="data">
        <feather-icon
          :id="`profile-row-${data.item.id}-update-submission`"
          icon="CheckCircleIcon"
          class="cursor-pointer"
          size="16"
          @click="markProfileAsSubmitted(data.item)"
          v-if="data.item.attributes.submittedToVa === 'no'"
        />

        <b-tooltip
          title="Mark as Submitted"
          class="cursor-pointer"
          :target="`profile-row-${data.item.id}-update-submission`"
        />

        <b-badge
          pill
          :variant="`light-${resolveProfileYNVariant(data.item.attributes.submittedToVa)}`"
          class="text-capitalize"
        >
          {{ title(data.item.attributes.submittedToVa) }}
        </b-badge>
      </template>

      <!-- Column: Total Students -->
      <template #cell(createdAt)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">
            {{ moment(data.item.createdAt).format('L') }}
          </span>
        </div>
      </template>

      <!-- Column: Total Students -->
      <template #cell(sessionDate)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">
            {{ moment(data.item.sessionDate).format('L') }}
          </span>
        </div>
      </template>

      <!-- Column: Status -->
      <template #cell(status)="data">
        <b-badge pill :variant="`light-${resolveProfileStatusVariant(data.item.status)}`" class="text-capitalize">
          {{ title(data.item.status) }}
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
          <template #button-content>
            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
          </template>

          <b-dropdown-item :to="{ name: 'apps-roster-profile-edit', params: { id: data.item.id } }">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>

          <b-dropdown-item @click="removeStudentsFromSession([data.item.id])">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Remove</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start"> </b-col>
        <!-- Pagination -->
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalProfiles"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <session-view-add-student
      @refreshStudents="$emit('refreshStudents')"
      :session-data="sessionData"
      :is-add-new-student-active.sync="isAddNewStudentActive"
    />
    <profile-upload-document
      :is-upload-document-open.sync="upload.isUploadDocumentOpen"
      :profile="upload.profile"
      :document-category="upload.documentCategory"
      :document-type="upload.documentType"
      v-on:refresh="refetchData"
    />
  </b-card-actions>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardBody,
  BCardTitle,
  BCardText,
  BTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ref, onUnmounted, computed } from '@vue/composition-api';
import { title, avatarText } from '@core/utils/filter';
import useSessionStudentsList from './useSessionStudentsList';
import rosterStoreModule from '../rosterStoreModule';
import moment from 'moment';

import SessionViewAddStudent from './SessionViewAddStudent';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import ProfileUploadDocument from '@/views/apps/roster/sessions-view/components/ProfileUploadDocument';

export default {
  components: {
    ProfileUploadDocument,
    SessionViewAddStudent,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardBody,
    BCardTitle,
    BCardActions,
    BCardText,
    BTooltip,

    vSelect,
  },
  props: {
    sessionData: { type: Object, required: true },
    sessionId: { type: String },
  },
  methods: {
    refetchData() {
      this.$emit('refresh', true);
    },
    startUpload(profile, documentCategory, documentType) {
      this.upload = {
        isUploadDocumentOpen: true,
        profile,
        documentCategory,
        documentType,
      };
    },

    markProfileAsSubmitted(profile) {
      store
        .dispatch('app-roster/updateProfile', {
          id: profile.id,
          profileData: {
            attributes: { ...profile.attributes, submittedToVa: 'yes' },
          },
        })
        .then((response) => {
          this.refetchData();
        });
    },
    async removeStudentsFromSession(profileIds) {
      if (!profileIds || profileIds.length === 0) {
        console.log(`no students selected or invalid id lsit`);
        return;
      }

      const response = await this.$store
        .dispatch('app-roster/removeStudentsFromSession', {
          sessionId: this.sessionData.id,
          profileIds,
        })
        .catch((error) => {
          console.error(`failed to remove students to class`, error);
        });

      if (!response) return false;
      this.$emit('refreshStudents');

      console.log(`added removed`, response.data);
    },
    assignNewStudent() {},
    doClassEdit(clazz) {
      console.log(`editing`, clazz);
    },
  },
  data: () => {
    return {
      isAddNewStudentActive: false,
      editClassData: {},

      upload: {
        isUploadDocumentOpen: false,

        profile: undefined,
        documentType: undefined,
        documentCategory: undefined,
      },
    };
  },
  mounted() {},
  setup(props) {
    const SESSION_APP_STORE_MODULE_NAME = 'app-roster';

    // Register module
    if (!store.hasModule(SESSION_APP_STORE_MODULE_NAME))
      store.registerModule(SESSION_APP_STORE_MODULE_NAME, rosterStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SESSION_APP_STORE_MODULE_NAME)) store.unregisterModule(SESSION_APP_STORE_MODULE_NAME);
    });

    const {
      perPage,
      currentPage,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refStudentsListTable,
      dataMeta,

      tableColumns,
      fetchProfiles,
      searchQuery,
      totalProfiles,

      resolveProfileStatusVariant,
      resolveProfileProgramVariant,
      resolveProfilePaymentStatusVariant,
      resolveProfileYNVariant,
    } = useSessionStudentsList(props.sessionId);

    return {
      // Sidebar

      fetchProfiles,

      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refStudentsListTable,
      totalProfiles,

      dataMeta,

      moment,

      searchQuery,

      resolveProfileStatusVariant,
      resolveProfileProgramVariant,
      resolveProfilePaymentStatusVariant,
      resolveProfileYNVariant,

      // Filter
      avatarText,
      title,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
