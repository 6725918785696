<template>
  <b-card>
    <div class="d-flex flex-wrap">
      <b-button variant="outline-danger" block @click="takeClassAttendance" v-if="false"> Take Attendance </b-button>
      <b-button variant="outline-warning" block @click="downloadRosterSheet"> Download Roster Sheet </b-button>
    </div>

    <b-modal
      id="modal-send-broadcast"
      cancel-variant="outline-secondary"
      ok-title="Send Broadcast"
      cancel-title="Close"
      centered
      title="Send email to all students in course"
    >
      <b-form>
        <b-form-group>
          <label for="message">Your Message</label>
          <b-form-textarea id="message" v-model="broadcastEmail" style="min-height: 480px"></b-form-textarea>
        </b-form-group>
      </b-form>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BImg,
  BMedia,
  BAvatar,
  BButton,
  BDropdown,
  BDropdownItem,
  BModal,
  BForm,
  BFormGroup,
  BFormTextarea,
  BCardText,
} from 'bootstrap-vue';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import store from '@/store';
import fileDownload from 'js-file-download';

export default {
  components: {
    BButton,
    BCard,
    BImg,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BModal,
    BForm,
    BFormGroup,
    BFormTextarea,
    BCardText,
  },
  props: {
    sessionData: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      broadcastEmail: `Hello class,\n\n\${{ messageContent }}\n\nThank you,\n\${{ instructorFullName }}`,
    };
  },
  methods: {
    takeClassAttendance() {
      console.log(`Mark class attendance`);
    },
    sendEmailBroadcast() {
      console.log(`send email broadcast`);
    },
  },
};
</script>

<style></style>
