<template>
  <b-card
    :img-src="require(`@/assets/images/banner/banner-42-${skin || 'dark'}.jpg`)"
    img-alt="Profile Cover Photo"
    img-top
    img-height="100"
    class="card-profile"
  >
    <div class="profile-image-wrapper">
      <div class="profile-image p-0">
        <b-avatar
          :src="sessionData.avatar"
          :text="avatarText(sessionData.name)"
          :variant="`light-${resolveSessionStatusVariant(sessionData.status)}`"
          size="104px"
        />
      </div>
    </div>
    <h3>{{ sessionData.name }}</h3>
    <h6 v-if="sessionData.startDate && sessionData.endDate" class="text-muted mb-1">
      {{ moment.utc(sessionData.startDate).format('L') }}
      {{ ` — ` }}
      {{ moment.utc(sessionData.endDate).format('L') }}
    </h6>
    <h6 class="text-muted">
      <b-badge class="profile-badge m-0" variant="light-primary">
        {{ title(sessionData.status + ` | ` + sessionData.visibility) }}
      </b-badge>
    </h6>

    <hr class="mb-2" />

    <!-- follower projects rank -->
    <div class="d-flex justify-content-between align-items-center" v-if="sessionData">
      <div>
        <h6 class="text-muted font-weight-bolder">Total Students</h6>
        <h3 class="mb-0">
          {{ sessionData.profiles.length }}
        </h3>
      </div>
      <div>
        <h6 class="text-muted font-weight-bolder">Total Classes</h6>
        <h3 class="mb-0">
          {{ sessionData.courses.map((course) => course.classes.length).reduce((a, b) => a + b, 0) }}
        </h3>
      </div>
    </div>

    <b-row class="mt-1">
      <b-button :disabled="isBtnBusy" @click="downloadRosterSheet" variant="outline-primary" size="sm" block>
        Download Roster
      </b-button>

      <b-button :to="{ name: 'apps-roster-session-edit', params: { id: sessionData.id } }" variant="primary" size="sm" block>
        Edit Session
      </b-button>

      <b-button
        :disabled="isBtnBusy"
        v-if="$can('integrations.monday.export.session') && sessionData.metadata.primaryPathway"
        @click="exportPlacementToMonday(false)"
        @click.shift="exportPlacementToMonday(true)"
        variant="success"
        size="sm"
        block
      >
        Export to Monday
      </b-button>

      <b-dropdown text="Take Attendance" style="padding-top: 5px; padding-bottom: 5px; width: 100%;"
                  v-if="$can('roster.attendance.update')" split right dropright variant="info"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'" size="sm" block @click="openTakeAttendance">
        <b-dropdown-item v-if="$can('roster.attendance.export')" @click="downloadAttendanceSheet">
          Download
        </b-dropdown-item>
      </b-dropdown>

      <!--  TODO: this creation needs to be more involved. If we don't have an applicable template for each of the
              accounts then that wouldn't be great
      -->
      <b-button
        v-if="$can('accounting.receivable.cohort.create') && !sessionData.metadata.receivableCohort"
        :disabled="isBtnBusy"
        variant="warning"
        size="sm"
        block
        @click="exportPlacementToReceivable(false)"
      >
        Create AR Cohort
      </b-button>
      <b-button
        v-else-if="$can('accounting.receivable.cohort.create') && sessionData.metadata.receivableCohort"
        variant="outline-warning"
        size="sm"
        block
        :to="{ name: `apps-accounting-receivable-cohort-view`, params: { id: sessionData.metadata.receivableCohort } }"
      >
        View AR Cohort
      </b-button>
    </b-row>
    <!--/ follower projects rank -->

    <AttendanceModal v-if="sessionData && isAttendanceOpen" :session-data.sync="sessionData" :is-table-open.sync="isAttendanceOpen" />
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BBadge,
  BForm,
  BFormGroup,
  BFormTextarea,
  BDropdown,
  BDropdownItem
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import useSessionsList from '../sessions-list/useSessionsList';
import { title } from '@core/utils/filter';
import usAppConfig from '@core/app-config/useAppConfig';
import fileDownload from 'js-file-download';
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive';
import AttendanceModal from './components/AttendanceModal'
import moment from 'moment'

const { skin } = usAppConfig();

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardText,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    vSelect,
    BForm,
    BFormGroup,
    BFormTextarea,
    BDropdown,
    BDropdownItem,
    AttendanceModal
  },
  props: {
    sessionData: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      isBtnBusy: false,

      isAttendanceOpen: false,
      attendanceAttendedOptions: [{ label: "Yes", value: true }, { label: "No", value: false }],
      attendanceTypeOptions: [
        { label: "Present", value: "present" },
        { label: "Absent", value: "absent" },
        { label: "Late", value: "late" },
        { label: "Special Case", value: "special_case" }
      ],

      attend: { type: "present", attended: false, notes: "" }
    };
  },
  methods: {
    openTakeAttendance() {
      this.isAttendanceOpen = true;
      // this.$bvModal.show('attendance-modal')
    },
    async downloadRosterSheet() {
      this.isBtnBusy = true;

      this.$store
        .dispatch("app-roster/fetchSessionRoster", {
          id: this.sessionData.id
        })
        .then((response) => {
          this.isBtnBusy = false;
          const { data } = response;
          fileDownload(data, `${this.sessionData.name} Roster.xlsx`);
        })
        .catch((error) => {
          this.isBtnBusy = false;
          console.error(`failed to add students to class`, error);
        });
    },
    async downloadAttendanceSheet() {
      this.isBtnBusy = true;

      this.$store
        .dispatch("app-roster/exportSessionAttendance", {
          id: this.sessionData.id
        })
        .then((response) => {
          this.isBtnBusy = false;
          const { data } = response;
          fileDownload(data, `${this.sessionData.name} Attendance.xlsx`);
        })
        .catch((error) => {
          this.isBtnBusy = false;
        });
    },
    async exportPlacementToMonday(doIntegrity) {
      this.isBtnBusy = true;

      this.$store
        .dispatch('app-roster/exportSessionToMonday', {
          sessionId: this.sessionData.id,
          integrity: doIntegrity,
        })
        .then((response) => {
          this.isBtnBusy = false;
          const { data } = response;
          console.log(data);

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Exported items to monday`,
              icon: 'ArchiveIcon',
              variant: 'success',
              text: `A total of ${data.length} profiles were eligible and published`,
            },
          });
        })
        .catch((error) => {
          this.isBtnBusy = false;
          console.error(`failed to export session to monday`, error);
        });
    },

    async exportPlacementToReceivable() {
      this.isBtnBusy = true;

      this.$store
        .dispatch('app-accounting-receivable/importSession', {
          sessionId: this.sessionData.id,
        })
        .then((response) => {
          this.isBtnBusy = false;
          const { data } = response;
          console.log(data);

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Created accounts receivable cohort`,
              icon: 'ArchiveIcon',
              variant: 'success',
              text: `The accounting receivable cohort + relevant accounts have been created`,
            },
          });

          this.$emit('refresh', {});
        })
        .catch((error) => {
          this.isBtnBusy = false;
          console.error(`failed to export session to monday`, error);
        });
    },
  },
  setup() {
    const { resolveSessionStatusVariant } = useSessionsList();
    return {
      avatarText,
      resolveSessionStatusVariant,
      title,
      skin,
      moment
    };
  },
};
</script>

<style></style>
